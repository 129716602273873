
  /* ReportViewer.css */
  .report-viewer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .fetch-report-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .fetch-report-btn:hover {
    background-color: #218838;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
  }
  
  .error-message {
    color: red;
    text-align: center;
    font-weight: bold;
  }

  .buttons{
    display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
background: #ffffff;
  }

  .button-left{
    color: #202224;
font-family: "Nunito Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
opacity: 0.7;
padding: 16px;
  }

  /* .button-left h1{
    margin-left: 15px;
  } */

  .button-right{
    display: flex;
padding: 12px 16px;
justify-content: center;
align-items: center;
gap: 10px;
   
  }


  .noreport-container{
    display: flex;
padding: 64px 56px;
flex-direction: column;
align-items: center;
flex: 1 0 0;
align-self: stretch;
border-radius: 20px;
background: #FFF;
  }

  .noreport{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 48px;
flex: 1 0 0;
align-self: stretch;
  }

  .noreport-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .noreport-header h1{
    width: 670px;
    color: #000A6F;
text-align: center;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }

  .noreport-header h3{
    align-self: stretch;
    color: #385071;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }