/* Container styling to constrain the dropdown size */
.filters-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;

}

.filters {
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    gap: 16px;
    margin-bottom: 1rem;
}

.filter {
  flex: 1 1 200px;
  margin-bottom: 15px;
}

.clear-filters-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-filters-btn:hover {
  background-color: #0056b3;
}

.select-dropdown {
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  width: 100%; /* Ensures full width */
}

.select-dropdown .multi-value-container {
  display: flex;
  flex-wrap: nowrap; 
  overflow-x: auto; 
  overflow-y: hidden; 
  max-width: 100%; 
  height: 40px;
  white-space: nowrap;
  padding: 2px;
}

.select-dropdown .multi-value {
  display: inline-flex;
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 12px;
  padding: 4px 8px;
  margin-right: 5px;
}

.select-dropdown .multi-value-label {
  font-size: 12px;
  overflow: hidden; 
  text-overflow: ellipsis;
}

.select-dropdown .multi-value-remove {
  color: #007bff;
  cursor: pointer;
}
