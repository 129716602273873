/* General Table Container Styling */
.table-container {
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 80vh; /* Fixed height for vertical scrolling */
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    white-space: nowrap;
}

/* Table */
.table {
    border-spacing: 0;
    width: 100%;
    min-width: 1200px;
    font-family: "Roboto", sans-serif;
    text-align: left;
    border-collapse: collapse;
}

/* Table Headers */
.scrollable-header {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 5;
}

/* Sticky Header */
.table-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 4; /* Ensure it's above other content */
  }


  .table-header .sticky-total {
    z-index: 4; /* Higher z-index to stay on top */
  }
  
  .title-row th {
    position: sticky;
    top: 0; /* Sticky at the top */
    background-color: #E6ECF8; /* Light blue background */
    color: #002F7B; /* Dark blue text */
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
    padding: 12px; /* Spacing */
    border-bottom: 2px solid #BCCCE0; /* Optional: Border */
    z-index: 4; /* Ensure title is above other rows */
    width: 100%; /* Ensure it spans full width */
}


.table-title {
    padding: 12px 15px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    background-color: #f1f1f1;
}

/* Header Row */
.header-row {
    
}

.header-cell {
    
    background: #E6ECF8; /* Light blue background */
    color: #002F7B; /* Dark blue text */
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    padding: 8px; /* Adjust padding for better spacing */
    text-align: left; /* Align text left */
    border-bottom: 2px solid #BCCCE0; /* Optional: Add a bottom border */
}


/* Hover effect for headers */
.header-cell:hover {
    background-color: #e9ecef;
}

/* Tooltip for headers */
.header-cell[data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 0.85rem;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    z-index: auto;
    transition: opacity 0.2s;
}

.header-cell[data-tooltip]:hover::after {
    opacity: 1;
}



.header-cell.sticky-column {
    position: sticky;
    z-index: 2;
    background: #E6ECF8;
}

.table-cell.sticky-column {
    position: sticky;
    z-index: 2;
    background: #E6ECF8;
}


.header-cell.sticky-column:nth-child(1) { left: 0px; }
.header-cell.sticky-column:nth-child(2) { left: 120px; } 
.header-cell.sticky-column:nth-child(3) { left: 180px; } 
.header-cell.sticky-column:nth-child(4) { left: 240px; } 


.table-cell.sticky-column:nth-child(1) { left: 0px; }
.table-cell.sticky-column:nth-child(2) { left: 120px; } 
.table-cell.sticky-column:nth-child(3) { left: 180px; } 
.table-cell.sticky-column:nth-child(4) { left: 240px; } 





/* Sticky Total Column */
.sticky-total-header {
    position: sticky;
    right: 0;
    background-color: #e6ecf8;
    color: #002f7b;
    z-index: 3;
    width: 145px;
    height: 10vh;
    padding: 8px 16px;
    font-weight: 700;
}

.table .th{
    background: cyan;
}

/* Table Body */
.table-body {
    position: relative;
}

.table-row {
    transition: background-color 0.2s ease-in-out;
    border-bottom: 1px solid #e6ecf8;
    white-space: wrap;  /*for wrap text*/
}

.table-row .group-row{
    border-top: 1px solid #B8C9E9;
background: #F4F8FF;
}



/* Alternating row colors */
.table-row:nth-child(even) {
    background-color: #f9f9f9;
}

.table-row:nth-child(odd) {
    background-color: #ffffff;
}

/* Row hover effect */
.table-row:hover {
    background-color: #eaf6ff;
}

/* Table Data Cells */
.table-cell {
    padding: 8px 16px;
    border-right: 1px solid #b8c9e9;
    background: #fff;
    color: #001e86;
    font-size: 14px;
    font-weight: 400;
}



/* Cell hover effect */
.table-cell:hover {
    background-color: #eaf6ff;
}

/* Aggregated Rows (GOP, Profit/Loss) */
.gop-row {
    background-color: #fff !important;
    position: sticky;
    top: 0;
    z-index: 2;
}

.aggregated {
    font-weight: bold;
}

/* Sticky Total Column */
.sticky-total {
    position: sticky;
    right: 0;
    background-color: #e6ecf8;
    z-index: 2;
    font-weight: bold;
    width: 145px;
    padding: 8px 16px;
    color: #27b264;
}

.group-toggle-container {
    display: flex;
    align-items: center;
    gap: 8px; /* Ensures an 8px gap between text and icon */
}

.group-toggle {
    cursor: pointer;
    display: flex; /* Ensures icon remains properly sized */
    align-items: center;
}


.dropdown-div {
}

/* Expand/Collapse Buttons */
.expand-collapse-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.expand-btn, .collapse-btn {
    background-color: #B8C9E9;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.2s;
}

.expand-btn:hover, .collapse-btn:hover {
    background-color: #B8C9E9;
}

/* Custom Scrollbar */
.table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #B8C9E9;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background-color: #B8C9E9;
}

.table-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .table {
        font-size: 0.9rem;
    }

    .table-container {
        max-height: 500px;
    }
}

@media (max-width: 768px) {
    .table {
        font-size: 0.85rem;
    }

    .header-cell, .table-cell {
        padding: 10px;
    }

    .table-container {
        max-height: 400px;
    }
}
