/* PowerBIPage.css */
.report-style-class {
  height: 100% !important; /* Ensure the height is set to 100% */
  width: 100% !important; /* Ensure the width is set to 100% */
  border: none; /* Optional: Remove borders for a cleaner look */
}

.report-style-class iframe {
  border: 0;
}
